import React, {useContext} from "react";
import AdminDrawer from "../layouts/AdminDrawer";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme } from "../../themes/darkTheme";
import { lightThemeAdmin } from "../../themes/lightTheme"
import { UserContext } from "../../contexts/UserContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "calc(100vh - 65px)",
    overflowY: "auto",
    marginTop: "65px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function EntityListFrame(props) {
  const [userContext,] = useContext(UserContext);
  const classes = useStyles();
  return (
    <ThemeProvider theme={userContext && userContext.themeColor === 1 ?  lightThemeAdmin : darkTheme}>
      <CssBaseline />
      <div className={classes.root}>
        <AdminDrawer />
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {props.children}
            </Grid>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default EntityListFrame;
