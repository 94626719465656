import React, { useState, useContext, useRef } from "react";
import {Grid, Divider, TextField, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LoadingBar from "react-top-loading-bar";
import { useHistory } from "react-router";
import apiService from "../../../services/api.service";
import { UserContext } from "../../../contexts/UserContext";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import Button from "../../../wrappers/Button"
import Alert from "../../../wrappers/Alert"


const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

function ChangePassword(props) {
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [displayPasswordError, setDisplayPasswordError] = useState("");
  const [displayConfirmPasswordError,setDisplayConfirmPasswordError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [displayOldPasswordError, setDisplayOldPasswordError] = useState("");
  const [userContext,] = useContext(UserContext);
  const history = useHistory();
  const progressRef = useRef(null);
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    cleanErrors();
    let error = false;

    if (!oldPassword || oldPassword.trim() === "") {
      setDisplayOldPasswordError("Old password is a required field");
      error = true;
    }

    if (!password || password.trim() === "") {
      setDisplayPasswordError("Password is a required field");
      error = true;
    }
    if (!confirmPassword || confirmPassword.trim() === "") {
      setDisplayConfirmPasswordError("Confirm password is a reuqired field");
      error = true;
    }

    if (!error) {
      if (password.trim() !== confirmPassword.trim()) {
        setDisplayConfirmPasswordError(
          "Password and Confirm password does not match"
        );
        error = true;
      }
    }

    if (!error) {
      progressRef.current.continuousStart();
      apiService
        .changePassword(oldPassword, password, confirmPassword)
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            for (let error of result.errors) {
              if (error.propertyName === "OldPassword")
                setDisplayOldPasswordError(error.errorMessage);
              else if (error.propertyName === "NewPassword")
                setDisplayPasswordError(error.errorMessage);
              else if (error.propertyName === "ConfirmPassword")
                setDisplayConfirmPasswordError(error.errorMessage);
            }
          }
          else
             setSaveSuccess(true);

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    }
  };

  const cleanErrors = () => {
    setSaveSuccess(false);
    setDisplayOldPasswordError("");
    setDisplayPasswordError("");
    setDisplayConfirmPasswordError("");
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          Your password was changed!
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid className={classes.topMargin} container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          ></Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Old Password"
                      margin="dense"
                      type="password"
                      name="oldpassword"
                      error={!(displayOldPasswordError === "")}
                      helperText={
                        displayOldPasswordError === ""
                          ? ""
                          : displayOldPasswordError
                      }
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                      value={oldPassword || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="New Password"
                      margin="dense"
                      type="password"
                      name="name"
                      error={!(displayPasswordError === "")}
                      helperText={
                        displayPasswordError === "" ? "" : displayPasswordError
                      }
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      value={password || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm New Password"
                      margin="dense"
                      type="password"
                      name="confirm"
                      error={!(displayConfirmPasswordError === "")}
                      helperText={
                        displayConfirmPasswordError === ""
                          ? ""
                          : displayConfirmPasswordError
                      }
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      value={confirmPassword || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} type="submit"
          className={classes.buttons}
          >
            Save
          </Button>
          <Button
            theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
            className={clsx(
                classes.buttons, classes.buttonRight
              )}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
}

export default ChangePassword;
