import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import { useHistory } from "react-router";

import Collapse from "@material-ui/core/Collapse";
import apiService from "../../../services/api.service";
import clsx from "clsx";
import Button from "../../../wrappers/Button";
import Alert from "../../../wrappers/Alert";

import { Grid, Divider, Box, Card, CardActions, CardContent, Avatar, TextField, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LoadingBar from "react-top-loading-bar";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewIcon from "@material-ui/icons/Pageview";
import red from "@material-ui/core/colors/red";
import { ReloadContext } from "../../../contexts/ReloadContext";
import { UserContext } from "../../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

const AccountEntry = (props) => {
  const [profileFile, setProfileFile] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [image, setImage] = useState();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [company, setCompany] = useState();
  const [contact, setContact] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState("");
  const [,setReloadContext] = useContext(ReloadContext);
  const [userContext,] = useContext(UserContext);

  const history = useHistory();
  let { id } = useParams();

  const inputFile = useRef();

  useEffect(() => {
    if (id) {
      progressRef.current.continuousStart();
      apiService
        .getTenant(id)
        .then((result) => {
          setCompany(result.data.name);
          setContact(result.data.contactEmail);
          setProfileImageSrc(result.data.imageUrl);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();

          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    }
  }, []);

  const classes = useStyles();
  const progressRef = useRef(null);

  const isEdit = () => {
    return id ? true : false;
  };

  const handleSubmit = (event) => {
    progressRef.current.continuousStart();
    event.preventDefault();
    clearErrors();
    const formData = new FormData();
    formData.append("image", image);

    if (!isEdit()) {
      apiService
        .createAccount(formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: {
            hasImage: hasImage(),
            name: company,
            contactEmail: contact,
          },
        })
        .then((result) => {
          if (result.errors.length > 0) {
            for (let error of result.errors) {
              handleSubmitError(error);
            }
          } else {
            setDisableSaveButton(true);
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
    } else {
      apiService
        .updateAccount(formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: {
            hasImage: hasImage(),
            id: id,
            name: company,
            contactEmail: contact,
          },
        })
        .then((result) => {
          if (result.errors.length > 0) {
            for (let error of result.errors) {
              console.log("error", error);
              handleSubmitError(error);
            }
          } else {
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
    }
  };

  const handleSubmitError = (error) => {
    if (error.propertyName === "Name") {
      setNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === "ContactEmail") {
      setContactEmailErrorMessage(error.errorMessage);
    }
  };

  const clearErrors = () => {
    setNameErrorMessage("");
    setContactEmailErrorMessage("");
  };

  const uploadSingleFile = (e) => {
    let inputFile = e.target.files[0];
    if (inputFile && inputFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (inputFile.size > 150000) {
        if (inputFile && inputFile.current) inputFile.current.value = "";
        setErrorAlertOpen(true);
      } else {
        setImage(e.target.files[0]);
        setErrorAlertOpen(false);
        setProfileFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const hasImage = () => {
    if (image || profileImageSrc) return true;
    else return false;
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Collapse in={errorAlertOpen}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="error"
          onClose={() => {
            setErrorAlertOpen(false);
          }}
          className={classes.alert}
        >
          The image cannot have a size greater than 150kb
        </Alert>
      </Collapse>
      <Collapse in={saveSuccess}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          The account was successfully {isEdit() ? "updated" : "created"}
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <Grid item xs={12}>
              <Card className={classes.root}>
                <div className={classes.rightTopCorner}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setProfileFile();
                      setProfileImageSrc();
                      setImage();
                    }}
                  >
                    <DeleteIcon
                      size="small"
                      className={classes.actionButtonDelete}
                    />
                  </IconButton>
                </div>
                <CardContent>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Avatar
                      variant="square"
                      className={classes.avatar}
                      src={
                        profileFile
                          ? profileFile
                          : profileImageSrc
                          ? profileImageSrc
                          : ""
                      }
                    >
                      <PageviewIcon />
                    </Avatar>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    single
                    ref={inputFile}
                    onChange={uploadSingleFile}
                    type="file"
                  />
                  <label
                    htmlFor="contained-button-file"
                    style={{ width: "100%" }}
                  >
                    <Button
                      theme={
                        userContext && userContext.themeColor === 1
                          ? "light"
                          : "dark"
                      }
                      style={{ width: "100%", marginTop: "10px" }}
                      component="span"
                    >
                      Change Image
                    </Button>
                  </label>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Company"
                      margin="dense"
                      name="company"
                      onChange={(e) => setCompany(e.target.value)}
                      required
                      error={!(nameErrorMessage === "")}
                      helperText={
                        !(nameErrorMessage === "") ? nameErrorMessage : ""
                      }
                      value={company || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Contact Email"
                      margin="dense"
                      name="contact"
                      type="email"
                      error={!(contactEmailErrorMessage === "")}
                      helperText={
                        !(contactEmailErrorMessage === "")
                          ? contactEmailErrorMessage
                          : ""
                      }
                      onChange={(e) => setContact(e.target.value)}
                      required
                      value={contact || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button
            theme={
              userContext && userContext.themeColor === 1 ? "light" : "dark"
            }
            type="submit"
            disabled={disableSaveButton}
            className={classes.buttons}
          >
            Save
          </Button>
          <Button
            theme={
              userContext && userContext.themeColor === 1 ? "light" : "dark"
            }
            className={clsx(classes.buttons, classes.buttonRight)}
            onClick={() => props.history.goBack()}
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default withRouter(AccountEntry);
