import React, {useEffect, useState, useRef,useContext} from 'react'
import clsx from "clsx";
import AdminDrawer from "../layouts/AdminDrawer";
import { useHistory  } from 'react-router'
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "../parts/Chart.js";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme } from "../../themes/darkTheme"
import TitleCard from '../parts/TitleCard';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../themes/lightTheme';
import apiService from '../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from "../../contexts/UserContext";
import moment from 'moment';
import { ReloadContext } from "../../contexts/ReloadContext";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "calc(100vh - 65px)",
    overflowY: "auto",
    marginTop: "65px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: "none",
    position: 'relative',
   },

  media: {
    background: "rgba(39,62,84,0.82)",
    opacity: 0.7,
 },
 overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
 }
}));

const chartDataConvert = (items) => {
  let result = [];
  items.forEach(element => {
    let time = moment(element.dateTime).format('L');
    let amount = element.count;
    result.push({time, amount});
  });
  return result;
};

function Dashboard(props)
{
    const history = useHistory();
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const progressRef = useRef(null);
    const [categoryData, setCategoryData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [userContext,] = useContext(UserContext);
    const [reloadContext,] = useContext(ReloadContext);

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService.getDashboard().then((result) => {
      setCategoryData(chartDataConvert(result.data.categories));
      setProductData(chartDataConvert(result.data.products));
      setAccountData(chartDataConvert(result.data.tenants));
      setUserData(chartDataConvert(result.data.users));
      progressRef.current.complete();
    })

    .catch((err)=> { 
      console.log("error: ", err);
      if(progressRef && progressRef.current)
        progressRef.current.complete();
      if(err.response && err.response.status === 401)
        history.push("/login");
    })
  },[reloadContext]);

    return (
      <>
         <ThemeProvider theme={userContext && userContext.themeColor === 1 ?  lightThemeAdmin : darkTheme}>
          <CssBaseline />
          <div className={classes.root}>
            <LoadingBar color="#f11946" ref={progressRef} height="3px" />
            <AdminDrawer />
            <main className={classes.content}>
              {/* <div className={classes.appBarSpacer} /> */}
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                  <TitleCard
                    title="Dashboard"
                    imgSrc="/images/hong-kong-1990268_1920.jpg"
                  />
                  <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                      <Chart title="Accounts" mysource={accountData} label="Amount" />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                      <Chart title="Users" mysource={userData} label="Amount"/>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                      <Chart title="Products" mysource={productData} label="Amount"/>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                      <Chart title="Categories"  mysource={categoryData} label="Amount"/>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </main>
          </div>
        </ThemeProvider>
      </>
    );
}

export default Dashboard;