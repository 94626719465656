import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import Dashboard from "./components/pages/Dashboard";
import Categories from "./components/pages/Categories/Categories";
import Products from "./components/pages/Products/Products";
import Users from "./components/pages/Users/Users";
import Accounts from "./components/pages/Accounts/Accounts";
import { UserContext } from "./contexts/UserContext";
import { AdminDrawerContext } from "./contexts/AdminDrawerContext";
import { ReloadContext } from "./contexts/ReloadContext";
import { ProtectedRoute } from "./protected.route";
import { lightTheme } from "./themes/lightTheme";
import { ThemeProvider } from "@material-ui/styles";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import ConfirmEmail from "./components/pages/ConfirmEmail";
import UserProfile from "./components/pages/UserProfile/UserProfile";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

let routes = (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/confirmemail" component={ConfirmEmail} />
    <Route exact path="/linkedin" component={LinkedInPopUp} />
    <ProtectedRoute exact path="/dashboard" component={Dashboard} />
    <ProtectedRoute path="/accounts" component={Accounts} />
    <ProtectedRoute path="/categories" component={Categories} />
    <ProtectedRoute path="/products" component={Products} />
    <ProtectedRoute path="/users" component={Users} />
    <ProtectedRoute path="/profile" component={UserProfile} />
    <Route exact path="/">
      <Redirect to="/dashboard" />
    </Route>
  </Switch>
);

function App() {
  const [userContext, setUserContext] = useState({ firstName: "", themeColor:"0" });
  const [adminDrawerContext, setAdminDrawerContext] = useState(true);
  const [reloadContext, setReloadContext] = useState();
  const history = useHistory();

  return (
    <Router history={history}>
      <ThemeProvider theme={lightTheme}>
        <UserContext.Provider value={[userContext, setUserContext]}>
          <ReloadContext.Provider value={[reloadContext, setReloadContext]}>
            <AdminDrawerContext.Provider value={[adminDrawerContext, setAdminDrawerContext]}>
              <div className="App">{routes}</div>
            </AdminDrawerContext.Provider>
          </ReloadContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
