import React, { useRef } from "react";
import TitleCard from "../../parts/TitleCard";
import Grid from "@material-ui/core/Grid";
import UserProfileMain from "./UserProfileMain";
import LoadingBar from "react-top-loading-bar";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { Redirect } from "react-router";
import EntityListFrame from "../../layouts/EntityListFrame";

function UserProfile(props) {
  const progressRef = useRef(null);
  let match = useRouteMatch();

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <EntityListFrame><TitleCard title="User Profile" imgSrc="/images/avenue-2215317_1920_2.jpg"/>
      <Grid item xs={12}>
        <Switch>
          <Route exact path={`${match.path}/change-password`}>
            <ChangePassword />
          </Route>
          <Route exact path={`${match.path}`}>
            <UserProfileMain />
          </Route>
          <Redirect to="/dashboard" />
          <Route />
        </Switch>
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default UserProfile;
