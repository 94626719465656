import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from  '../../wrappers/Button';
import EditIcon  from "@material-ui/icons/Edit";
import DeleteIcon  from "@material-ui/icons/Delete";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Avatar from '@material-ui/core/Avatar';
import { UserContext } from "../../contexts/UserContext";

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">
            
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  addButton:
  {
    marginRight: theme.spacing(1),
  },
  avatar:
  {
    height:"35px",
    width:"35px",
  }
}));

const EnhancedTableToolbar = (props) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
      {!props.canAdd ? (
        " "
      ) : (
        <Button
          className={classes.addButton}
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          onClick={props.handleAdd}
          startIcon={<AddCircleOutlineIcon />}
        >
          ADD
        </Button>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {

};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  actionButtonEdit: {
    // marginRight: theme.spacing(1),
    color: green[500]
  },
  actionButtonDelete: {
    color: red[500]
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionCell : {
    minWidth: "130px"
  }
}));

export default function EntityTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = props.rows;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.handleRequestSort(isAsc ? 'desc' : 'asc', property);
   
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.handleChangeRowsPerPage(parseInt(event.target.value, 10));
  };

 const emptyRows =  0 

 const getTableRow = (row, num) => {
   if (row && row.image)
     return (
       <TableCell align={getTableRowAlign(num)}>
         <img src={row.image} alt="logo" />
       </TableCell>
     );
   else if (row && row.avatar)
     return (
       <TableCell align={getTableRowAlign(num)}>
         <Avatar variant="square" src={row.avatar} className={classes.avatar}></Avatar>
       </TableCell>
     );
   else return <TableCell align={getTableRowAlign(num)}>{row}</TableCell>;
 };

 const getTableRowAlign = (num) =>
 {
   return props.headCells[num-1].numeric  ? 'right' : 'left';
 }

  return (
      <>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar {...props} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size= 'medium'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells = {props.headCells}
            />
            <TableBody>
              {
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row[0]}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row[1]}
                      </TableCell>

                      {row[2] !== undefined ? getTableRow(row[2], 2) : ""}
                      {row[3] !== undefined ? getTableRow(row[3], 3) : ""}
                      {row[4] !== undefined ? getTableRow(row[4], 4) : ""}
                      {row[5] !== undefined ? getTableRow(row[5], 5) : ""}
                      {row[6] !== undefined ? getTableRow(row[6], 6) : ""}
                      {row[7] !== undefined ? getTableRow(row[7], 7) : ""}
                      {row[8] !== undefined ? getTableRow(row[8], 8) : ""}
                      {row[9] !== undefined ? getTableRow(row[9], 9) : ""}

                      <TableCell align="right" className={classes.actionCell}>
                        <IconButton aria-label="edit" data-tag={row[0]} onClick={props.handleEdit}>
                          <EditIcon
                            size="small"
                            className={classes.actionButtonEdit}
                          />
                        </IconButton>
                        <IconButton aria-label="delete" data-tag={row[0]} onClick={props.deleteHandler}>
                          <DeleteIcon
                            size="small"
                            className={classes.actionButtonDelete}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.totalNumberOfRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
