import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router";
import { UserContext } from "./contexts/UserContext";
import authService from './services/auth.service';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [context, setContext] = useContext(UserContext);
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) => {
          console.log(authService.getAuthenticationContext() && authService.getAuthenticationContext().user)
        if (authService.getAuthenticationContext() && authService.getAuthenticationContext().user) {
          return <Component {...props} />;
        } else {
          history.push("/login");
        }
      }}
    />
  );
};
