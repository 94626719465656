import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import authService from "../../services/auth.service";
import { UserContext } from "../../contexts/UserContext";
import { AdminDrawerContext } from "../../contexts/AdminDrawerContext";
import { ReloadContext } from "../../contexts/ReloadContext";
import apiService from "../../services/api.service";
import logo_company from "../../assets/Hire-Quality-Q.gif";
import clsx from "clsx";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Refresh from "@material-ui/icons/Refresh";
import { useMediaLayout } from "use-media";
import NotificationPopup from "../parts/NotificationPopup";
import useComponentVisible from "../../hooks/useComponentVisible";
import { Link } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AppsIcon from "@material-ui/icons/Apps";
import CategoryIcon from "@material-ui/icons/Category";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerButtons: {
    color: theme.palette.adminDrawer.button,
  },
  logo: {
    maxWidth: 35,
  },
  minWidth: {
    minWidth: "35px",
  },
  avatar: {
    height: "35px",
    width: "35px",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.adminDrawer.main,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.adminDrawer.drawer,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.adminDrawer.button,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    paddingLeft: "10px",
    flexGrow: 1,
    color: theme.palette.adminDrawer.text,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: theme.palette.adminDrawer.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  customBadge: {
    backgroundColor: "#BB86FC",
    color: "white",
  },
  text: {
    color: theme.palette.adminDrawer.text,
  },
  'message-text > a': {
      color: theme.palette.adminDrawer.text
  },
  divider: {
    background: theme.palette.adminDrawer.divider,
},
}));

export default function AdminDrawer()  {
  const classes = useStyles();
  const [profileUrl, setProfileUrl] = useState("");
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);
  const [userContext, setUserContext] = useContext(UserContext);
  const [adminDrawerContext, setAdminDrawerContext] = useContext(
    AdminDrawerContext
  );
  const [reloadContext, setReloadContext] = useContext(ReloadContext);
  const [notificationBadge, setNotificationBadge] = useState();

  // const [openNotificationPopup, setOpenNotificationPopup] = useState(false);

  const isWide = useMediaLayout({ maxWidth: "1400px" });
  const history = useHistory({ forceRefresh: true });
  const {
    ref1,
    ref2,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userContext === undefined || userContext.id === undefined) {
          const result = await apiService.getMe();
          setUserContext(result);
        }
      } catch {
        history.push("/login");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (notificationBadge && !isComponentVisible) {
      var messages = [];
      var messagesToCleanUp = userContext.messages.filter(
        (x) => x.isRead === false
      );

      for (let mess of messagesToCleanUp) messages.push(mess.id);

      if (messages.length > 0) {
        apiService.setMessageAsRead(messages).then(() => {
          apiService.getMe().then((result) => {
            setUserContext(result);
          });
        });
      }
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isWide) {
      setAdminDrawerContext(false);
    }
  }, [isWide]);

  useEffect(() => {
    setProfileUrl(
      userContext && userContext.avatarExtension
        ? "/dynamic/user/images/" +
            userContext.id +
            "." +
            userContext.avatarExtension +
            "?v" +
            moment().format("YYYYMMDDhhmm")
        : ""
    );
    setNotificationBadge(
      userContext && userContext.messages && userContext.messages.filter(x => x.isRead === false).length
    );
  }, [userContext]);

  const handleMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleLogout = (event) => {
    authService.logout();
    setUserContext(null);
    history.push("/login");
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setAdminDrawerContext(true);
  };
  const handleDrawerClose = () => {
    setAdminDrawerContext(false);
  };

  return (
    <div className={classes.root}>
      <div
        ref={ref1}
        style={{
          position: "absolute",
          top: "42px",
          right: "85px",
          zIndex: "1500",
        }}
      >
        {isComponentVisible && (
          <p onClick={() => setIsComponentVisible(true)}>
            <NotificationPopup messages = {userContext.messages}/>
          </p>
        )}
      </div>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          adminDrawerContext && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              adminDrawerContext && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo_company} alt="logo" className={classes.logo} />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Welcome{" "}
            {userContext && userContext.firstName
              ? userContext.firstName === ""
                ? userContext.email
                : userContext.firstName
              : ""}
          </Typography>
          <Tooltip title="Refresh">
            <IconButton
              color="inherit"
              className={classes.drawerButtons}
              onClick={() => setReloadContext(new Date())}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications" ref={ref2}>
            <IconButton
              color="inherit"
              className={classes.drawerButtons}
              onClick={() => {
                isComponentVisible
                  ? setIsComponentVisible(false)
                  : setIsComponentVisible(true);
              }}
            >
              <Badge
                badgeContent={notificationBadge}
                classes={{ badge: classes.customBadge }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {userContext && userContext.id ? (
                <Avatar src={profileUrl} className={classes.avatar}></Avatar>
              ) : (
                ""
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={profileAnchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openProfile}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                <ListItemIcon className={classes.minWidth}>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.minWidth}>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !adminDrawerContext && classes.drawerPaperClose
          ),
        }}
        open={adminDrawerContext}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} className={classes.text}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.text}>
          <Tooltip title="Dashboard" placement="left">
            <ListItem button component={Link} to="/">
              <ListItemIcon className={classes.text}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Accounts" placement="left">
            <ListItem button component={Link} to="/accounts">
              <ListItemIcon className={classes.text}>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Accounts" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Users" placement="left">
            <ListItem button component={Link} to="/users">
              <ListItemIcon className={classes.text}>
                <Group />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Categories" placement="left">
            <ListItem button component={Link} to="/categories">
              <ListItemIcon className={classes.text}>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Products" placement="left">
            <ListItem button component={Link} to="/products">
              <ListItemIcon className={classes.text}>
                <FastfoodIcon />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>
          </Tooltip>
        </List>
        <Divider className={classes.divider} />
        <List className={classes.text}>
          <Tooltip title="Developer API" placement="left">
            <ListItem button component="a" href="/swagger" target="new">
              <ListItemIcon className={classes.text}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Dev API" />
            </ListItem>
          </Tooltip>
        </List>
        <Divider className={classes.divider}/>
      </Drawer>
    </div>
  );
}
