import React, { useState, useRef } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import LoadingBar from "react-top-loading-bar";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import apiService from "../../services/api.service";
import Typography from "@material-ui/core/Typography";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [sentStatus, setSendStatus] = useState(false);
  const history = useHistory();
  const progressRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidEmailAddress(email)) {
      progressRef.current.continuousStart();
      apiService
        .forgotPassword(email)
        .then((result) => {
          setSendStatus(true);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error", err);
          setDisplayError(true);
        });
    } else setDisplayError(true);
  };

  const isValidEmailAddress = (address) => {
    return !!address.match(/.+@.+/);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    buttons: {
      margin: theme.spacing(1),
    },
    buttons2: {
      margin: theme.spacing(5),
    },
  }));

  const classes = useStyles();

  const FinishedForm = (props) => {
    return (
      <div
        style={{
          height: "580px",
          paddingTop: "100px",
          maxWidth: "650px",
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <h3>Please check your email for further instructions</h3>
          </Grid>
          <img alt="email-sent" src="/images/email-sent.png" />
        </Grid>
        <Grid
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              style={{ textTransform: "none" }}
              className={classes.buttons2}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                history.push("/login");
              }}
              variant="contained"
            >
              Back to login page
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const ForgotPassform = (props) => {
    return (
      <div
        style={{
          height: "580px",
          paddingTop: "100px",
          maxWidth: "650px",
          position: "relative",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={8}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12} alignItems="right">
              <div style={{ paddingTop: "40px" }}>
                <Grid
                  container
                  spacing={8}
                  xs={12}
                  md={12}
                  lg={12}
                  alignItems="right"
                >
                  <Grid item>
                    <Typography
                      component="h5"
                      variant="h5"
                      color="inherit"
                      noWrap
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      color="inherit"
                      noWrap
                    >
                      <ol>
                        <li>Enter your email address below.</li>
                        <li>Our system will send you a temporary link</li>
                        <li>Use the link to reset your password</li>
                      </ol>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={8}
                  xs={12}
                  alignItems="flex-end"
                  style={{ marginTop: "-50px" }}
                >
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      error={displayError}
                      helperText={
                        displayError ? "This is not a valid email address" : ""
                      }
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={8}
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Button
                      style={{ textTransform: "none" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Send Email
                    </Button>
                    <Button
                      style={{ textTransform: "none" }}
                      className={classes.buttons}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/login");
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Back to login page
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Container maxWidth="sm">
        {sentStatus ? <FinishedForm /> : <ForgotPassform />}
      </Container>
    </div>
  );
}
