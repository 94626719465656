import React from "react";
import StandardButton from "@material-ui/core/Button";

function Button(props) {
    const {theme} = props;
    
    return theme === "dark" ? (
      <StandardButton variant="outlined" {...props}>
        {props.children}
      </StandardButton>
    ) : (
      <StandardButton variant="contained" color="primary" {...props}>
        {props.children}
      </StandardButton>
    );
}

export default Button;
