import axios from 'axios';
import apiHelperService from "./api.helper.service";

class apiService {
  forgotPassword(email) {
    axios
      .post(`/api/v1/user/command/forgot-password-restore`, { email: email })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(password, email, code) {
    axios
      .post(`/api/v1/user/command/restore-password-by-code`, {
        code: code,
        email: email,
        newPassword: password,
      })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }
  
  confirmEmail(email, code) {
    return axios
      .patch(`/api/v1/user/command/confirm-email`, {Email: email, Code: code})
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  signUp(companyName, username, firstname, lastname, password)
  {
    return axios
    .post(`/api/v1/tenant/command/sign-up`, {
        CompanyName: companyName,
        Username: username,
        Firstname: firstname,
        Lastname: lastname,
        Password: password
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
  }

  changePassword = (oldPassword, password, confirmPassword) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/user/command/change-password`, {OldPassword:oldPassword, NewPassword: password, ConfirmPassword: confirmPassword})
  }

  getTenants = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/account/query/accounts`, {params: {Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage}});
  }

  getTenant = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/account/query/account/${id}`);
  }


  getUsers = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/users`, {params: {Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage}});
  }

  getUser = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/user/${id}`);
  }

  getCategories = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/category/query/categories`, {params: {Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage}});
  }

  getCategoriesForTenant = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/category/query/categories/${id}`);
  }

   getCategory = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/category/query/category/${id}`);
  }

  getProducts = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/product/query/products`, {params: {Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage}});
  }

  getProduct = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/product/query/product/${id}`);
  }

  getDashboard = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/dashboard/query/dashboard`);
  }

  getMe = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/me`);
  };

  getUserProfile = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/user-profile`);
  }

  postUserProfile =(formData, params) => 
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/user/command/update-profile", formData, params)
  }

  createUser = (formData, params) => 
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/user/command/create", formData, params)
  }

  updateUser = (formData, params) => 
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/user/command/update", formData, params)
  }

  deleteUser=(id) =>
  {
    return apiHelperService.deleteCallAPIWrapper("/api/v1/user/command/delete/"+id);
  }

  setMessageAsRead = (messages) =>
  {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/message/command/set-as-read`, {messageIds:messages})
  }

  createAccount = (formData, params) => 
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/account/command/create", formData, params)
  }

  updateAccount = (formData, params) => 
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/account/command/update", formData, params)
  }

  deleteAccount=(id) =>
  {
    return apiHelperService.deleteCallAPIWrapper("/api/v1/account/command/delete/"+id);
  }

  createCategory=(params) =>
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/category/command/create", params)
  }

  updateCategory=(params) =>
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/category/command/update", params)
  }

  deleteCategory=(id) =>
  {
    return apiHelperService.deleteCallAPIWrapper("/api/v1/category/command/delete/"+id);
  }

  createProduct=(params) =>
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/product/command/create", params)
  }

  updateProduct=(params) =>
  {
    return apiHelperService.postCallAPIWrapper("/api/v1/product/command/update", params)
  }

  deleteProduct=(id) =>
  {
    return apiHelperService.deleteCallAPIWrapper("/api/v1/product/command/delete/"+id);
  }
}

export default new apiService();
