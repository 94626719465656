import React, { useEffect, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import EntityTable from "../../parts/EntityTable";
import TitleCard from "../../parts/TitleCard";
import apiService from "../../../services/api.service";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { useHistory, Redirect } from "react-router";
import DeleteDialog from "../../parts/DeleteDialog";
import CategoryEntry from "./CategoryEntry";
import { ReloadContext } from "../../../contexts/ReloadContext";
import EntityListFrame from "../../layouts/EntityListFrame";
import { Switch, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "../../../protected.route";


function Categories(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState("");
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: "0",
  });
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [reloadContext,] = useContext(ReloadContext);
  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getCategories(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
        progressRef.current.complete();
      })
      .catch((err) => {
        console.log("error: ", err);
        if (progressRef && progressRef.current) progressRef.current.complete();
        if (err.response && err.response.status === 401) history.push("/login");
      });
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([
        item.id,
        item.tenantName,
        item.name,
        item.sortOrder,
        c.format("L"),
        u.format("L"),
      ]);
    });
    return newArray;
  };

  const headCells = [
    {
      id: "TenantName",
      numeric: false,
      disablePadding: true,
      label: "Account",
    },
    { id: "Name", numeric: false, disablePadding: false, label: "Name" },
    {
      id: "SortOrder",
      numeric: true,
      disablePadding: false,
      label: "Sort Order",
    },
    {
      id: "CreatedDate",
      numeric: true,
      disablePadding: false,
      label: "Created Date",
    },
    {
      id: "UpdatedDate",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
    },
  ];

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    apiService
      .deleteCategory(id)
      .then((result) => {
        setDummyCounter(dummyCounter + 1);
      })
      .catch((err) => {
        console.log("error: ", err);
        if (err.response && err.response.status === 401) history.push("/login");
      });
  };

  const handleAdd = () => {
    history.push("/categories/add");
  };

  const handleEdit = (event) => {
    history.push("/categories/" + event.currentTarget.dataset.tag + "/edit");
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />

      <EntityListFrame>
        <TitleCard
          title="Categories"
          imgSrc="/images/eclipse-1492818_1920.jpg"
        />
        <Grid item xs={12}>
          {rows === "" ? (
            ""
          ) : (
            <Switch>
              <ProtectedRoute exact path={`${match.path}/add`}>
                <CategoryEntry action="add" history={history} />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}/:id/edit`}>
                <CategoryEntry action="edit" history={history} />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}`}>
                <EntityTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                  canAdd={true}
                />
              </ProtectedRoute>
              <Redirect to="/dashboard" />
              <ProtectedRoute />
            </Switch>
          )}
        </Grid>
        <DeleteDialog
          action={pendingDeleteAction}
          handleDelete={handleDelete}
          title="Delete Category?"
          body="Are you sure you want to delete this category with all its data? Once deleted it cannot be undone."
        />
      </EntityListFrame>
     </>
  );
}

export default Categories;
