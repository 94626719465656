import React, { useState, useContext, useRef } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import authService from "../../services/auth.service";
import { useHistory  } from 'react-router'
import { UserContext } from "../../contexts/UserContext";
import LoadingBar from 'react-top-loading-bar';
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import apiService from "../../services/api.service";
import FacebookIcon from "@material-ui/icons/Facebook";
import GoogleIcon from "@material-ui/icons/Google";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { lightTheme } from "../../themes/lightTheme"
import { ThemeProvider } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import GoogleLogin from 'react-google-login';
import Alert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayErrorText, setDisplayErrorText] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState("");
    const history = useHistory();
    const [,setContext] = useContext(UserContext);
    const inputRef = useRef();
    const defaultErrorText = "The username either doesn't exists or the password is wrong";
    const progressRef = useRef(null);

    const useStyles = makeStyles((theme) => ({
      root: {
        display: "flex",
        height: "calc(100vh)",
        ["@media (min-height:768px)"]: { // eslint-disable-line no-useless-computed-key
          alignItems: "center",
          justifyContent: "center",
        },

        overflowY: "auto",
        position: "relative",
      },
      rootContainer: {
        width: "1800px",
        marginLeft: "0px",
        marginRight: "20px",
        ["@media (min-height:768px)"]: { // eslint-disable-line no-useless-computed-key
          marginTop: "-150px",
        },
        border: "1px",
      },
      rightContainer :
      {
        paddingTop: "20px",
        ["@media (min-width:960px)"]: { // eslint-disable-line no-useless-computed-key
        paddingTop: "100px",
        },
        maxWidth: "650px",
        position: "relative",
      },
      title: {
        fontStyle: "italic",
      },
      googleIcon: {
        backgroundColor: "#FD397A",
        color: "#ffffff",
        margin: theme.spacing(1),
      },
      facebookIcon: {
        backgroundColor: "#5867DD",
        color: "#ffffff",
        margin: theme.spacing(1),
      },
      twitterIcon: {
        backgroundColor: "#5578EB",
        color: "#ffffff",
        margin: theme.spacing(1),
      },
    }));

    const responseFacebook = (response) => {
      progressRef.current.continuousStart();
      authService
      .loginFacebook(response.userID, response.accessToken)
      .then((result) => {
        apiService.getMe().then((result) => {
          setContext(result);
          setTimeout(() => {
            history.push("/");
          }, (350));
          progressRef.current.complete();
        })  
      })
      .catch((error) => {
        setAlertErrorMessage("No user registered with this social identity exists in the system. Please Sign Up first");
        setErrorAlertOpen(true);
        if(progressRef && progressRef.current)
          progressRef.current.complete();
      });
    }

    const successResponseGoogle = (response) =>
    {
        authService
        .loginGoogle(response.googleId, response.tokenObj.id_token)
        .then((result) => {
          apiService.getMe().then((result) => {
            setContext(result);
            setTimeout(() => {
              history.push("/");
            }, (350));
            progressRef.current.complete();
          })  
        })
        .catch((error) => {
          setAlertErrorMessage("No user registered with this social identity exists in the system. Please Sign Up first");
          setErrorAlertOpen(true);
          if(progressRef && progressRef.current)
            progressRef.current.complete();
        });
    }

    const failedResponseGoogle = (response) =>
    {
        console.log(response)
    }

    const responseLinkedIn = (response) => {
        authService
        .loginLinkedIn(response.code)
        .then((result) => {
          apiService.getMe().then((result) => {
            setContext(result);
            setTimeout(() => {
              history.push("/");
            }, (350));
            progressRef.current.complete();
          })  
        })
        .catch((error) => {
          setAlertErrorMessage("No user registered with this social identity exists in the system. Please Sign Up first");
          setErrorAlertOpen(true);
          if(progressRef && progressRef.current)
            progressRef.current.complete();
        });
    }

    const classes = useStyles();

      const handleSubmit = (event) => {
        event.preventDefault();
        progressRef.current.continuousStart();
        authService
          .login(email, password)
          .then((result) => {
            apiService.getMe().then((result) => {
              setContext(result);
              setTimeout(() => {
                history.push("/");
              }, (350));
              progressRef.current.complete();
            })  
          })
          .catch((error) => {
            if(progressRef && progressRef.current)
              progressRef.current.complete();
            setDisplayErrorText(defaultErrorText);
            try {
              if (error.response && error.response.data.errors[0].includes("confirmed")) {
                setDisplayErrorText(error.response.data.errors[0]);
              }
            } catch (err) {}
          });
      };

    return (
      <>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
            open={errorAlertOpen}
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            <Alert
            variant="outlined"
              onClose={() => {
                setErrorAlertOpen(false);
              }}
              severity="error"
            >
             {alertErrorMessage}
            </Alert>
          </Snackbar>
          <div className={classes.root}>
            <LoadingBar color="#f11946" ref={progressRef} height="3px" />
            <Grid container className={classes.rootContainer} spacing="3">
              <Hidden smDown>
                <Grid item xs={6}>
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", left: "100px" }}>
                      <img
                        alt="logo"
                        src="/images/Software_Horizontal_medium_size.jpg"
                      ></img>
                    </div>
                    <div
                      style={{
                        height: "580px",
                        position: "relative",
                        backgroundImage: `url('/images/dotted_background.jpg')`,
                        backgroundPosition: "right bottom",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <h2 style={{ justify: "center" }}>
                        <Typography variant="h5" color="inherit" noWrap>
                          Demo - Portal
                        </Typography>
                      </h2>
                      <div className="sticky">
                        <b>Note:</b> <br />
                        username: admin@hqs.dev password: Pvi7Zt6c
                        <hr />
                        The database is cleaned out once a day
                      </div>
                    </div>
                  </div>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6} lg={6}>
                <div className={classes.rightContainer}>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={8}
                      direction="row"
                      justify="flex-start"
                      alignItems="stretch"
                    >
                      <Hidden mdUp>
                        <Grid item xs={12}>
                          <img
                            alt="logo"
                            src="/images/Software_Horizontal_medium_size.jpg"
                          ></img>
                        </Grid>
                      </Hidden>
                      <Grid
                        item
                        xs={12}
                        align="center"
                        style={{ paddingTop: "0px" }}
                        alignItems="center"
                      >
                        <Grid
                          container
                          xs={12}
                          alignItems="flex-start"
                          justify="flex-end"
                        >
                          <Grid item>
                            <Button
                              disableFocusRipple
                              disableRipple
                              style={{ textTransform: "none" }}
                              variant="text"
                              color="primary"
                              className={classes.title}
                              onClick={() => history.push("/signup")}
                            >
                              Don't have an account yet? Sign Up!
                            </Button>
                          </Grid>
                        </Grid>
                        <div style={{ paddingTop: "40px" }}>
                          <Grid
                            container
                            spacing={8}
                            xs={12}
                            md={12}
                            lg={12}
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography
                                component="h5"
                                variant="h5"
                                color="inherit"
                                noWrap
                              >
                                <Hidden mdUp>Demo-Portal </Hidden>
                                Sign In
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={8}
                            xs={12}
                            alignItems="flex-end"
                          >
                            <Grid item md={true} sm={true} xs={true}>
                              <TextField
                                label="Username"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                ref={inputRef}
                                fullWidth
                                autoFocus
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={8}
                            xs={12}
                            alignItems="flex-end"
                          >
                            <Grid item md={true} sm={true} xs={true}>
                              <TextField
                                label="Password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={!(displayErrorText === "")}
                                helperText={
                                  displayErrorText === ""
                                    ? ""
                                    : displayErrorText
                                }
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={8}
                            xs={12}
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item>
                              <Button
                                disableFocusRipple
                                disableRipple
                                style={{ textTransform: "none" }}
                                variant="text"
                                color="primary"
                                onClick={() => history.push("/forgotpassword")}
                              >
                                Forgot password ?
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                style={{ textTransform: "none" }}
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                Sign In
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container></Grid>
                          <div className="kt-divider">
                            <span></span>
                            <span>OR</span>
                            <span></span>
                          </div>

                          <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                            render={(renderProps) => (
                              <Button
                                onClick={renderProps.onClick}
                                style={{ textTransform: "none" }}
                                variant="contained"
                                color="primary"
                                className={classes.googleIcon}
                                startIcon={<GoogleIcon />}
                              >
                                Google
                              </Button>
                            )}
                            buttonText="Login"
                            onSuccess={successResponseGoogle}
                            onFailure={failedResponseGoogle}
                            cookiePolicy={"single_host_origin"}
                          />

                          <LinkedIn
                            clientId={process.env.REACT_APP_LINKEDIN_CLIENTID}
                            onFailure={responseLinkedIn}
                            onSuccess={responseLinkedIn}
                            redirectUri={
                              process.env.REACT_APP_LINKEDIN_REDIRECT_URL
                            }
                            renderElement={({ onClick, disabled }) => (
                              <Button
                                onClick={onClick}
                                style={{ textTransform: "none" }}
                                color="primary"
                                variant="contained"
                                className={classes.twitterIcon}
                                startIcon={<LinkedInIcon />}
                              >
                                LinkedIn
                              </Button>
                            )}
                          />
                          <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APPID}
                            //autoLoad
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <Button
                                style={{ textTransform: "none" }}
                                color="primary"
                                variant="contained"
                                onClick={renderProps.onClick}
                                className={classes.facebookIcon}
                                startIcon={<FacebookIcon />}
                              >
                                Facebook
                              </Button>
                            )}
                          />
                        </div>
                        <Hidden mdUp>
                          <div class="sticky2">
                            <b>Note:</b> <br />
                            username: admin@hqs.dev password: Pvi7Zt6c
                            <hr />
                            The database is cleaned out once a day
                          </div>
                        </Hidden>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </>
    );
}

export default Login;
