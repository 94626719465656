import { createMuiTheme } from "@material-ui/core";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    adminDrawer: {
      main: "#000",
      text: "#fff",
      button: "#fff",
      drawer: "#424242",
      divider: "#595959"
    },
    primary: {
      main: "#BB86FC",
      //main: "#000"
    }
  },
});

