import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Divider, Box, Card, CardActions, CardContent, Avatar, TextField, IconButton } from "@material-ui/core";

import LoadingBar from 'react-top-loading-bar';
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import apiService from "../../../services/api.service";
import Alert from '../../../wrappers/Alert'
import Button from '../../../wrappers/Button'
import Collapse from "@material-ui/core/Collapse";
import moment from 'moment';
import { UserContext } from "../../../contexts/UserContext";
import { useHistory  } from 'react-router'
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root:
  {
    position:"relative"
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: "absolute",
    top: "0x",
    right: "0px",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

const UserProfileMain = (props) => {

  const [phoneNumber, setPhoneNumber] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [colorTheme, setColorTheme] = useState();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [profileFile, setProfileFile] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [image, setImage] = useState();
  const classes = useStyles();
  const inputFile = useRef();
  const progressRef = useRef(null);
  const [context, setContext] = useContext(UserContext);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getUserProfile()
      .then((result) => {
        setFirstname(result.data.firstname);
        setLastname(result.data.lastname);
        setPhoneNumber(result.data.phoneNumber);
        setColorTheme(result.data.themeColor);

        if (result.data.appId && result.data.avatarExtension)
          setProfileImageSrc(
            "/dynamic/user/images/" +
              result.data.appId +
              "." +
              result.data.avatarExtension + "?v="+moment().format('YYYYmmddss')
          );
          progressRef.current.complete()
      })
      .catch((err)=> {
        console.log("error: ", err);
        if(progressRef && progressRef.current)
          progressRef.current.complete();

        if(err.response && err.response.status === 401)
          history.push("/login");
      })
  }, []);

  const uploadSingleFile = (e) => {
    let inputFile = e.target.files[0];
    if (inputFile && inputFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (inputFile.size > 150000) {
        if(inputFile && inputFile.current)
          inputFile.current.value = "";
        setErrorAlertOpen(true);
      } else {
        setImage(e.target.files[0]);
        setErrorAlertOpen(false);
        setProfileFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const hasImage = () => {
    if (image || profileImageSrc) return true;
    else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveSuccess(false);
    progressRef.current.continuousStart();
    const formData = new FormData();
    formData.append("avatar", image);
    apiService.postUserProfile(formData, {headers: { 'Content-Type': 'multipart/form-data' }, params: {hasImage: hasImage(), firstname: firstname, phoneNumber: phoneNumber,lastname: lastname, colorTheme: colorTheme }}).then(() => {
      progressRef.current.complete();
      apiService.getMe().then((result) => {
        setContext(result);
        setSaveSuccess(true);
      }).catch((err)=> {
        console.log("error: ", err);
        if(progressRef && progressRef.current)
          progressRef.current.complete();
        if(err.response && err.response.status === 401)
          history.push("/login");
      })
    }).catch((err) => {
      if(progressRef && progressRef.current)
        progressRef.current.complete();
      console.log("error", err);
    });
  };

  return (
    <>
    <LoadingBar color="#f11946" ref={progressRef} height="3px" />
    <Collapse in={saveSuccess}>
        <Alert
          theme={context && context.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          Your profile was updated!
        </Alert>
      </Collapse>
      <Collapse in={errorAlertOpen}>
        <Alert
          theme={context && context.themeColor === 1 ? "light" : "dark"}
          severity="error"
          onClose={() => {
            setErrorAlertOpen(false);
          }}
          className={classes.alert}
        >
          The profile image cannot have a size greater than 150kb
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <Grid item xs={12}>
              <Card className={classes.root}>
                <div className={classes.rightTopCorner}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setProfileFile();
                      setProfileImageSrc();
                      setImage();
                    }}
                  >
                    <DeleteIcon
                      size="small"
                      className={classes.actionButtonDelete}
                    />
                  </IconButton>
                </div>
                <CardContent>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Avatar
                      className={classes.avatar}
                      src={
                        profileFile
                          ? profileFile
                          : profileImageSrc
                          ? profileImageSrc
                          : ""
                      }
                    />
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    single
                    ref={inputFile}
                    onChange={uploadSingleFile}
                    type="file"
                  />
                  <label
                    htmlFor="contained-button-file"
                    style={{ width: "100%" }}
                  >
                    <Button theme={context && context.themeColor === 1 ? "light" : "dark"} 
                      style={{ width: "100%", marginTop: "10px" }}
                      component="span"
                    >
                      Change Image
                    </Button>
                  </label>
                </CardActions>
              </Card>
              <Button
                style={{ marginTop: "14px" }}
                fullWidth
                onClick={() => history.push("/profile/change-password")}
                theme={context && context.themeColor === 1 ? "light" : "dark"} 
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Firstname"
                      margin="dense"
                      name="firstName"
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Lastname"
                      margin="dense"
                      name="lastName"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      name="phone"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Choose Color Theme"
                      margin="dense"
                      name="country"
                      select
                      onChange={(e) => setColorTheme(e.target.value)}
                      required
                      SelectProps={{ native: true }}
                      value={colorTheme}
                    >
                      <option key="key3" value="0">
                        Dark
                      </option>
                      <option key="key5" value="1">
                        Light
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button theme={context && context.themeColor === 1 ? "light" : "dark"} type="submit" className={classes.buttons}>
            Save
          </Button>
          <Button theme={context && context.themeColor === 1 ? "light" : "dark"}  className={clsx(
              classes.buttons, classes.buttonRight
            )} onClick={() => history.goBack()}>
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default UserProfileMain;
