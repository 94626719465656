import React, { useState,useRef, useContext} from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import apiService from "../../services/api.service";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

import FacebookIcon from "@material-ui/icons/Facebook";
import GoogleIcon from "@material-ui/icons/Google";
import LoadingBar from 'react-top-loading-bar';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import GoogleLogin from 'react-google-login';
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import authService from "../../services/auth.service";
import { UserContext } from "../../contexts/UserContext";
import Snackbar from '@material-ui/core/Snackbar';

function Signup(props) {
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [confPassword, setConfPassword] = useState("");

  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");
  const [firstnameErrorMessage, setFirstnameErrorMessage] = useState("");
  const [lastnameErrorMessage, setLastnameErrorMessage] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [confPasswordErrorMessage, setConfPasswordErrorMessage] = useState("");
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const progressRef = useRef(null);
  const [,setContext] = useContext(UserContext);

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      marginTop: successAlertOpen ? "0px" : "10vh",
    },
    alert: {
      margin: theme.spacing(2),
    },
    googleIcon: {
      backgroundColor: "#FD397A",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
    facebookIcon: {
      backgroundColor: "#5867DD",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
    twitterIcon: {
      backgroundColor: "#5578EB",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const clearErrorMessages = () => {
    setPassErrorMessage("");
    setFirstnameErrorMessage("");
    setLastnameErrorMessage("");
    setCompanyNameErrorMessage("");
    setUsernameErrorMessage("");
    setConfPasswordErrorMessage("");
  }

  const responseFacebook = (response) => {
    progressRef.current.continuousStart();
    authService
    .signUpFacebook(response.userID, response.accessToken)
    .then((result) => {
      apiService.getMe().then((result) => {
        setContext(result);
        setTimeout(() => {
          history.push("/");
        }, (350));
        progressRef.current.complete();
      })  
    })
    .catch((error) => {
      setErrorAlertOpen(true);
      if(progressRef && progressRef.current)
        progressRef.current.complete();
    });
  }

  const successResponseGoogle = (response) =>
  {
    progressRef.current.continuousStart();
       authService
      .signUpGoogle(response.googleId, response.tokenObj.id_token)
      .then((result) => {
        apiService.getMe().then((result) => {
           setContext(result);
          setTimeout(() => {
            history.push("/");
          }, (2000));
          progressRef.current.complete();
        })  
      })
      .catch((error) => {
        setErrorAlertOpen(true);
        if(progressRef && progressRef.current)
          progressRef.current.complete();
      });
  }

  const failedResponseGoogle = (response) =>
  {
      console.log(response)
  }

  const responseLinkedIn = (response) => {
    progressRef.current.continuousStart();
      authService
      .signUpLinkedIn(response.code)
      .then((result) => {
        apiService.getMe().then((result) => {
          setContext(result);
          setTimeout(() => {
            history.push("/");
          }, (2000));
          progressRef.current.complete();
        })  
      })
      .catch((error) => {
        setErrorAlertOpen(true);
        if(progressRef && progressRef.current)
          progressRef.current.complete();
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrorMessages();
    if (
      password.length > 0 &&
      confPassword.length > 0 &&
      password === confPassword
    ) {
      progressRef.current.continuousStart();
      apiService
        .signUp(companyName, userName, firstName, lastName, password)
        .then(() => {
          progressRef.current.complete();
          setSuccessAlertOpen(true);
        })
        .catch((err) => {
          if(progressRef && progressRef.current)
            progressRef.current.complete();
          err.response.data.errors.forEach((item) => {
            if (item.propertyName === "Password") {
              setPassErrorMessage(item.errorMessage);
            } else if (item.propertyName === "Firstname") {
              setFirstnameErrorMessage(item.errorMessage);
            } else if (item.propertyName === "Lastname") {
              setLastnameErrorMessage(item.errorMessage);
            } else if (item.propertyName === "CompanyName") {
              setCompanyNameErrorMessage(item.errorMessage);
            } else if (item.propertyName === "Username") {
              setUsernameErrorMessage(item.errorMessage);
            }
          });
        });
    }
    else
    {
      setConfPasswordErrorMessage("The password and confirm password fields do not match");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
            open={errorAlertOpen}
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            <Alert
            variant="outlined"
              onClose={() => {
                setErrorAlertOpen(false);
              }}
              severity="error"
            >
             You are already registered, please login using the login screen.
            </Alert>
          </Snackbar>
        <LoadingBar color="#f11946" ref={progressRef} height="3px" />
        <Container maxWidth="md">
          <Collapse in={successAlertOpen}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Alert
                  variant="outlined"
                  severity="success"
                  onClick={() => history.push("/login")}
                  className={classes.alert}
                  action={
                    <Button color="inherit" size="small">
                      Login
                    </Button>
                  }
                >
                  Your account registration is completed and a request for email
                  confirmation was sent to your inbox.
                </Alert>
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={!successAlertOpen}>
            <Grid container justify="center" alignItems="centers">
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", height: "130px" }}
              >
                <Typography component="h5" variant="h5" color="inherit">
                  Create a New Account
                </Typography>
                <br />
                <Typography component="p" variant="body1" color="inherit">
                  Come join our community! Let's set up your account. Already
                  have one?
                  <Button
                    style={{ textTransform: "none", marginLeft: "10px" }}
                    className={classes.buttons}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/login");
                    }}
                    variant="contained"
                    color="yellow"
                    href="/login"
                  >
                    Sign in here
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="p" variant="body1" color="inherit">
                  You can also signup with these:
                </Typography>
                <Grid
                  container
                  item
                  direction="row"
                  justify="space-between"
                  spacing={2}
                  style={{
                    paddingTop: "20px",
                    paddingRight: "60px",
                    height: "75%",
                  }}
                >
                  <Grid item xs={12}>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                      render={(renderProps) => (
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={renderProps.onClick}
                          variant="contained"
                          color="primary"
                          className={classes.googleIcon}
                          startIcon={<GoogleIcon />}
                          fullWidth
                        >
                          Google
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={successResponseGoogle}
                      onFailure={failedResponseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LinkedIn
                      clientId={process.env.REACT_APP_LINKEDIN_CLIENTID}
                      onFailure={responseLinkedIn}
                      onSuccess={responseLinkedIn}
                      redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URL}
                      renderElement={({ onClick, disabled }) => (
                        <Button
                          onClick={onClick}
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                          className={classes.twitterIcon}
                          startIcon={<LinkedInIcon />}
                          fullWidth
                        >
                          LinkedIn
                        </Button>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APPID}
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <Button
                          style={{ textTransform: "none" }}
                          color="primary"
                          variant="contained"
                          className={classes.facebookIcon}
                          onClick={renderProps.onClick}
                          startIcon={<FacebookIcon />}
                          fullWidth
                        >
                          Facebook
                        </Button>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                spacing={3}
                style={{ paddingLeft: "40px", borderLeft: "1px solid" }}
              >
                <Grid item xs={12}>
                  <Hidden smUp>
                    <div className="kt-divider">
                      <span></span>
                      <span>OR</span>
                      <span></span>
                    </div>
                  </Hidden>
                  <TextField
                    label="Company Name"
                    name="company"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    error={!(companyNameErrorMessage === "")}
                    helperText={
                      !(companyNameErrorMessage === "")
                        ? companyNameErrorMessage
                        : ""
                    }
                    fullWidth
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    name="newemail"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    type="email"
                    error={!(usernameErrorMessage === "")}
                    helperText={
                      !(usernameErrorMessage === "") ? usernameErrorMessage : ""
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Firstname"
                    name="firstname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    error={!(firstnameErrorMessage === "")}
                    helperText={
                      !(firstnameErrorMessage === "")
                        ? firstnameErrorMessage
                        : ""
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Lastname"
                    name="lastname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    error={!(lastnameErrorMessage === "")}
                    helperText={
                      !(lastnameErrorMessage === "") ? lastnameErrorMessage : ""
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!(passErrorMessage === "")}
                    helperText={
                      !(passErrorMessage === "") ? passErrorMessage : ""
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    name="password2"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    error={!(confPasswordErrorMessage === "")}
                    helperText={
                      !(confPasswordErrorMessage === "")
                        ? confPasswordErrorMessage
                        : ""
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ marginTop: "20px", paddingRight: "20px" }}
            >
              <Grid item xs={6}></Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  style={{ textTransform: "none" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Container>
      </div>
    </form>
  );
}

export default Signup;
