import { createMuiTheme } from '@material-ui/core';

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      // default: "#dddddd"
      default: "#FFFFFF"
    },
    primary: {
      main: '#4167B2'
    },
  }
});

export const lightThemeAdmin = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    adminDrawer: {
      main: "#3f51b5",
      text: "#fff",
      button: "#fff",
      drawer: "rgb(93,112,211)",
      divider: "#aaa"
    },
    background: {
      default: "#D0D0D0",
    },
  },
});