import React, {useContext} from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../contexts/UserContext";

export default function TitleCard(props) {
  const [userContext,] = useContext(UserContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    card: {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      boxShadow: "none",
      position: "relative",
    },

    media: {
      background: "rgba(39,62,84,0.82)",
      opacity: opacity(),
    },
    overlay: {
      position: "absolute",
      top: "20px",
      left: "20px",
      color: "white",
    },
  }));

  const opacity = () => {
    return userContext && userContext.themeColor === 1 ? "1" : "0.7";
  }

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt=""
            height="140"
            className={classes.media}
            image={props.imgSrc}
            title=""
          />
          <Typography
            variant="h4"
            color="inherit"
            noWrap
            className={classes.overlay}
          >
            {props.title}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
