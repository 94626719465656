import React, { useState, useEffect, useRef,useContext } from "react";
import { Grid, Divider, Box, Card, CardActions, CardContent, Avatar, TextField, IconButton } from "@material-ui/core";
import Button from '../../../wrappers/Button';
import Alert from '../../../wrappers/Alert';
import { makeStyles } from "@material-ui/styles";
import LoadingBar from 'react-top-loading-bar';
import { useHistory  } from 'react-router';
import DeleteIcon from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";
import apiService from "../../../services/api.service";
import { useParams } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import { ReloadContext } from "../../../contexts/ReloadContext";
import { UserContext } from "../../../contexts/UserContext";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

const UserEntry = (props) => {
  const [profileFile, setProfileFile] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [image, setImage] = useState();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [accountId, setAccountId] = useState();
  const [accounts, setAccounts] = useState();
  const [user, setUser] = useState();

  const [displayFirstnameError, setDsplayFirstnameError] = useState("");
  const [displayLastnameError, setDisplayLastnameError] = useState("");
  const [displayEmailError, setDisplayEmailError] = useState("");
  const [displayPhonenumberError, setDisplayPhonenumberError] = useState("");

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [,setReloadContext] = useContext(ReloadContext);
  const [userContext,] = useContext(UserContext);
  const {id} = useParams();
  const history = useHistory();

  const inputFile = useRef();

  useEffect(() => {
   if(id)
   {
     progressRef.current.continuousStart();
     apiService.getUser(id).then((result) => 
     {
       setUser(result.data);
       setFirstname(result.data.firstname);
       setLastname(result.data.lastname);
       setEmail(result.data.username);
       setPhonenumber(result.data.phoneNumber);
       setProfileImageSrc(result.data.imageUrl);
       progressRef.current.complete();
     }).catch((err) => {
       console.log("error: ", err);
       if(progressRef && progressRef.current)
         progressRef.current.complete();

       if(err.response && err.response.status === 401)
         history.push("/login");
     })
   }
 }, []);


 useEffect(() => {
  progressRef.current.continuousStart();
  if (isEdit()) {
    apiService.getTenants(0, 0, "desc", "Name").then((tenantResult) => {
      setAccounts(tenantResult.data.list);
      apiService
        .getUser(id)
        .then((userResult) => {
          setUser(userResult.data);
          setFirstname(userResult.data.firstname);
          setLastname(userResult.data.lastname);
          setEmail(userResult.data.username);
          setPhonenumber(userResult.data.phoneNumber);
          setProfileImageSrc(userResult.data.imageUrl);
          setAccountId(userResult.data.tenantID);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    });
  } else {
    apiService
      .getTenants(0, 0, "desc", "Name")
      .then((result) => {
        setAccounts(result.data.list);
        setAccountId(result.data.list[0].id);
        progressRef.current.complete();
      }).catch((err) => {
        console.log("error: ", err);
        if (progressRef && progressRef.current)
          progressRef.current.complete();
        if (err.response && err.response.status === 401)
          history.push("/login");
      });
  }
}, []);

  const cleanErrors = () => {
    setDsplayFirstnameError("");
    setDisplayLastnameError("");
    setDisplayEmailError("");
  }
  const validateError = (errors) =>
  {
    for (let error of errors) {
      if (error.propertyName === "FirstName")
        setDsplayFirstnameError(error.errorMessage);
      else if (error.propertyName === "LastName")
        setDisplayLastnameError(error.errorMessage);
      else if (error.propertyName === "Email")
        setDisplayEmailError(error.errorMessage);
    }
  }

  const classes = useStyles();
  const progressRef = useRef(null);

  const handleSubmit = (event) =>
  {
      event.preventDefault();
      cleanErrors();
      const formData = new FormData();
      formData.append("avatar", image);
      if(!isEdit())
      {
        apiService.createUser(formData, {headers: { 'Content-Type': 'multipart/form-data' }, params: {hasImage: hasImage(), firstname: firstname, phoneNumber: phonenumber,lastname: lastname, email: email, tenantID: accountId  }}).then((result) => {
          progressRef.current.complete();
          if (result.errors && result.errors.length > 0) {
              validateError(result.errors);
          }
          else
          {
            setDisableSaveButton(true);
            setSaveSuccess(true);
          }
        }).catch((err) => {
          if(progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
      }
      else
      {
        apiService.updateUser(formData, {headers: { 'Content-Type': 'multipart/form-data' }, params: {id: user.appId,hasImage: hasImage(), firstname: firstname, phoneNumber: phonenumber,lastname: lastname, email: email, tenantID: accountId  }}).then((result) => {
          progressRef.current.complete();
          setSaveSuccess(true);
        }).catch((err) => {
          if(progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
      }
      setReloadContext(new Date());
  }

  const uploadSingleFile = (e) => {
    let inputFile = e.target.files[0];
    if (inputFile && inputFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (inputFile.size > 150000) {
        if(inputFile && inputFile.current)
          inputFile.current.value = "";
        setErrorAlertOpen(true);
      } else {
        setImage(e.target.files[0]);
        setErrorAlertOpen(false);
        setProfileFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const isEdit = () =>
  {
      return id ? true : false;
  }

  const hasImage = () => {
    if (image || profileImageSrc) return true;
    else return false;
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          The user was successfully {isEdit() ? "udpated" : "created" }
        </Alert>
      </Collapse>
      <Collapse in={errorAlertOpen}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="error"
          onClose={() => {
            setErrorAlertOpen(false);
          }}
          className={classes.alert}
        >
          The image cannot have a size greater than 150kb
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
              <Grid item xs={12}>
              <Card className={classes.root}>
                <div className={classes.rightTopCorner}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setProfileFile();
                      setProfileImageSrc();
                      setImage();
                    }}
                  >
                    <DeleteIcon
                      size="small"
                      className={classes.actionButtonDelete}
                    />
                  </IconButton>
                </div>
                <CardContent>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Avatar
                      className={classes.avatar}
                      src={
                        profileFile
                          ? profileFile
                          : profileImageSrc
                          ? profileImageSrc
                          : ""
                      }
                    />
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    single
                    ref={inputFile}
                    onChange={uploadSingleFile}
                    type="file"
                  />
                  <label
                    htmlFor="contained-button-file"
                    style={{ width: "100%" }}
                  >
                    <Button
                      theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} 
                      style={{ width: "100%", marginTop: "10px" }}
                      component="span"
                    >
                      Change Image
                    </Button>
                  </label>
                </CardActions>
              </Card>
            </Grid>


          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                      fullWidth
                      label="Account"
                      margin="dense"
                      name="account"
                      select
                      disabled = {isEdit()}
                      onChange={(e) => setAccountId(e.target.value)}
                      required
                      SelectProps={{ native: true }}
                      value={accountId || ""}
                    >
                      { accounts && accounts.map((item) => {
                         return <option key={item.id} value={item.id}>{item.name || ""}</option>
                      })}
                    </TextField>
                  </Grid>
                <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Firstname"
                      margin="dense"
                      name="firstname"
                      error={!(displayFirstnameError === "")}
                      helperText={
                        displayFirstnameError === "" ? "" : displayFirstnameError
                      }
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Lastname"
                      margin="dense"
                      name="lastname"
                      error={!(displayLastnameError === "")}
                      helperText={
                        displayLastnameError === "" ? "" : displayLastnameError
                      }
                      onChange={(e) => setLastname(e.target.value)}
                     value={lastname || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="dense"
                      type="email"
                      name="email"
                      error={!(displayEmailError === "")}
                      helperText={
                        displayEmailError === "" ? "" : displayEmailError
                      }
                      onChange={(e) => setEmail(e.target.value)}
                      required
                     value={email || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      type="email"
                      name="phonenumber"
                      error={!(displayPhonenumberError === "")}
                      helperText={
                        displayPhonenumberError === "" ? "" : displayPhonenumberError
                      }
                      onChange={(e) => setPhonenumber(e.target.value)}
                     value={phonenumber || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} type="submit" disabled={disableSaveButton} className={classes.buttons}>
            Save
          </Button>
          <Button theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} className={clsx(
              classes.buttons, classes.buttonRight
            )} onClick={() => props.history.goBack()}>
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default UserEntry;
