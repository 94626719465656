import React, { useState, useRef } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";
import apiService from "../../services/api.service";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

export default function ResetPassword({ match, location }) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [successAlertOpen, setSuccessAlertOpen] = useState(true);
  const [errorAlertOpen, setErrorAlertOpen] = useState(true);
  const [alertErrorMessage, setAlertErrorMessage] = useState(true);
  const [actionResult, setActionResult] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const inputRef = useRef();
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3, 2),
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    alert: {
      margin: theme.spacing(1),
    },
    title: {
      fontStyle: "italic",
    },
    buttons: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const parsedqs = queryString.parse(location.search);

  const handleSubmit = (event) => {
    event.preventDefault();
    if((password1.length < 1 && password2.length < 1) || password1 !== password2)
    {
        setDisplayError(true);
    }
    else
    {
      setDisplayError(false);
  
      apiService.resetPassword(password1, parsedqs.email, parsedqs.code,).then((result) => {
        if (result === "success") {
          setSuccessAlertOpen(true);
          setActionResult("success");
          setTimeout(() => {
            history.push("/login");
          },3000)
        } else {
          if(result)
            setAlertErrorMessage(result.join(", "));
          else
            setAlertErrorMessage("Failed request");
          setErrorAlertOpen(true);
          setActionResult("failed");
        }
      })
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Typography component="h5" variant="h5" color="inherit" noWrap>
                Reset Password
              </Typography>
            </Grid>

              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  name="password1"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  type="password"
                  ref={inputRef}
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Confirm New Password"
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  error={displayError}
                  helperText={displayError ? "The two fields don't match" : ""}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  style={{ textTransform: "none" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Change Password
                </Button>
                <Button
                  style={{ textTransform: "none" }}
                  className={classes.buttons}
                  type="button"
                  onClick={(e) => { e.preventDefault(); history.push("/login")}}
                  variant="contained"
                  color="yellow"
                >
                  Back to login page
                </Button>
                </Grid>
                <Grid item xs={12}>
                  {actionResult === "success" ? (
                    <Collapse in={successAlertOpen}>
                      <Alert
                        variant="outlined"
                        severity="success"
                        onClose={() => {
                          setSuccessAlertOpen(false);
                        }}
                        className={classes.alert}
                      >
                        The password was changed!
                      </Alert>
                    </Collapse>
                  ) : actionResult === "failed" ? (
                    <Collapse in={errorAlertOpen}>
                      <Alert
                        variant="outlined"
                        severity="error"
                        onClose={() => {
                          setErrorAlertOpen(false);
                        }}
                        className={classes.alert}
                      >
                        The action failed. The resonse was: {alertErrorMessage}
                      </Alert>
                    </Collapse>
                  ) : (
                    ""
                  )}

            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
