import React, { useState, useEffect, useRef,  useContext  } from "react";
import { Grid, Divider, TextField, Card, CardContent,} from "@material-ui/core";
import Button from '../../../wrappers/Button'
import Alert from '../../../wrappers/Alert'
import { makeStyles } from "@material-ui/styles";
import LoadingBar from 'react-top-loading-bar';
import { useHistory, withRouter  } from 'react-router';
import { useParams } from "react-router-dom";
import apiService from "../../../services/api.service";

import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import { ReloadContext } from "../../../contexts/ReloadContext";
import { UserContext } from "../../../contexts/UserContext";


const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

const ProductEntry = (props) => {
  const [name, setName] = useState();
  const [SKU, setSKU] = useState();
  const [categoryId, setCategoryId] = useState();
  const [categories, setCategories] = useState();
  const [brand, setBrand] = useState();
  const [description, setDescription] = useState();
  const [accountId, setAccountId] = useState();
  const [accounts, setAccounts] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [userContext,] = useContext(UserContext);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [skuErrorMessage, setSkuErrorMessage] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [brandErrorMessage, setBrandErrorMessage] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [,setReloadContext] = useContext(ReloadContext);

  const progressRef = useRef(null);

  let {id} = useParams();
  let history = useHistory();

 useEffect(() => {
  progressRef.current.continuousStart();
    
  if (isEdit()) {
    apiService.getTenants(0, 0, "desc", "Name").then((tenantResult) => {
      setAccounts(tenantResult.data.list);
      apiService
        .getProduct(id)
        .then((productResult) => {
          setCategoryId(productResult.data.categoryID);
          setAccountId(productResult.data.tenantId);
          setSKU(productResult.data.sku);
          setName(productResult.data.name);
          setBrand(productResult.data.brand);
          setDescription(productResult.data.description);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    });
  } else {
    apiService
      .getTenants(0, 0, "desc", "Name")
      .then((result) => {
        setAccounts(result.data.list);
        setAccountId(result.data.list[0].id);
      }).catch((err) => {
        console.log("error: ", err);
        if (progressRef && progressRef.current)
          progressRef.current.complete();
        if (err.response && err.response.status === 401)
          history.push("/login");
      });
  }
}, []);

useEffect(() => {
  apiService.getCategoriesForTenant(accountId).then((categoryResult) => {
    setCategories(categoryResult.data);
    if(!categoryId)
       setCategoryId(categoryResult.data[0]);
  }).catch((err) => {
    console.log("error: ", err);
    if (progressRef && progressRef.current)
      progressRef.current.complete();
    if (err.response && err.response.status === 401)
      history.push("/login");
  });
}, [accountId]);

  const classes = useStyles();

  const handleSubmit = (event) =>
  {
      event.preventDefault();
      clearErrors();

      if(!isEdit())
      {
        apiService.createProduct({sku: SKU, name: name, categoryID: categoryId.id, brand: brand, description: description}).then((result) => {
          progressRef.current.complete();
          if(result.errors.length > 0)
          {
            for (let error of result.errors)
            {
               console.log("error", error);
                handleSubmitError(error);
            }
          }
          else
          {
            setDisableSaveButton(true);
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
        }).catch((err) => {
          if(progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
      }
      else
      {
        apiService.updateProduct({id:id,sku: SKU, name: name, categoryID: categoryId, brand: brand, description: description}).then((result) => {
          progressRef.current.complete();
          if(result.errors.length > 0)
          {
            for (let error of result.errors)
            {
               console.log("error", error);
              handleSubmitError(error);
            }
          }
          else
          {
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
        }).catch((err) => {
          if(progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
      }
  }


  const handleSubmitError = (error) => {
    if (error.propertyName === "Name") {
      setNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === "SKU") {
      setSkuErrorMessage(error.errorMessage);
    } else if (error.propertyName === "CategoryID") {
      setCategoryErrorMessage(error.errorMessage);
    } else if (error.propertyName === "Brand") {
      setBrandErrorMessage(error.errorMessage);
    } else if (error.propertyName === "Description") {
      setDescriptionErrorMessage(error.errorMessage);
    } 
  }

  const clearErrors = () =>
  {
    setSaveSuccess(false);
    setNameErrorMessage("");
    setSkuErrorMessage("");
    setCategoryErrorMessage("");
    setBrandErrorMessage("");
    setDescriptionErrorMessage("");
  }

  const isEdit = () =>
  {
      return id ? true : false;
  }

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          Your product was successfully {isEdit() ? "updated" : "created"}
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          ></Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <TextField
                      fullWidth
                      label="Account"
                      margin="dense"
                      name="account"
                      select
                      disabled = {isEdit()}
                      onChange={(e) => setAccountId(e.target.value)}
                      required
                      error={!(accountErrorMessage === "")}
                      helperText={
                        !(accountErrorMessage === "") ? accountErrorMessage : ""
                      }
                      SelectProps={{ native: true }}
                      value={accountId || ""}
                    >
                      { accounts && accounts.map((item) => {
                         return <option key={item.id} value={item.id}>{item.name || ""}</option>
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      margin="dense"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      error={!(nameErrorMessage === "")}
                      helperText={
                        !(nameErrorMessage === "") ? nameErrorMessage : ""
                      }
                      required
                      value={name || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="SKU"
                      margin="dense"
                      name="sortorder"
                      onChange={(e) => setSKU(e.target.value)}
                      error={!(skuErrorMessage === "")}
                      helperText={
                        !(skuErrorMessage === "") ? skuErrorMessage : ""
                      }
                      required
                      value={SKU || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Category"
                    margin="dense"
                    name="category"
                    select
                    onChange={(e) => setCategoryId(e.target.value)}
                    error={!(categoryErrorMessage === "")}
                    helperText={
                      !(categoryErrorMessage === "") ? categoryErrorMessage : ""
                    }
                    required
                    SelectProps={{ native: true }}
                    value={categoryId || ""}
                  >
                     { categories && categories.map((item) => {
                         return <option key={item.id} value={item.id}>{item.name || ""}</option>
                      })}
                  </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Brand"
                      margin="dense"
                      name="brand"
                      onChange={(e) => setBrand(e.target.value)}
                      error={!(brandErrorMessage === "")}
                      helperText={
                        !(brandErrorMessage === "") ? brandErrorMessage : ""
                      }
                      required
                      value={brand || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      margin="dense"
                      name="description"
                      error={!(descriptionErrorMessage === "")}
                      helperText={
                        !(descriptionErrorMessage === "") ? descriptionErrorMessage : ""
                      }
                      onChange={(e) => setDescription(e.target.value)}
                      required
                      value={description || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} type="submit" disabled={disableSaveButton} className={classes.buttons}>
            Save
          </Button>
          <Button theme={userContext && userContext.themeColor === 1 ? "light" : "dark"} className={clsx(
              classes.buttons, classes.buttonRight
            )} onClick={() => props.history.goBack()}>
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default withRouter(ProductEntry);
