import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from "recharts";
import Typography from "@material-ui/core/Typography";
import purple from '@material-ui/core/colors/purple';

export default function Chart(props) {
  const theme = useTheme();
  const data = props.mysource;

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="white" gutterBottom>
        {props.title}
      </Typography>
      <ResponsiveContainer>
        <LineChart 
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
            >
              { props.label }
            </Label>
          </YAxis>
          <Line
            type="monotone"
            dataKey="amount"
            stroke={purple[500]}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
