import React, { useEffect, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import EntityTable from "../../parts/EntityTable";
import TitleCard from "../../parts/TitleCard";
import apiService from "../../../services/api.service";
import LoadingBar from "react-top-loading-bar";
import AccountEntry from "./AccountEntry";
import { useHistory, Redirect } from "react-router";
import DeleteDialog from "../../parts/DeleteDialog";
import { ProtectedRoute } from "../../../protected.route";
import { Switch, useRouteMatch } from "react-router-dom";
import { ReloadContext } from "../../../contexts/ReloadContext";
import { UserContext } from "../../../contexts/UserContext";
import EntityListFrame from "../../layouts/EntityListFrame";

import { withRouter } from "react-router";

import moment from "moment";

function Accounts(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState("");
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [userContext,] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: "0",
  });
  const [reloadContext,] = useContext(ReloadContext);

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getTenants(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log("error: ", err);
        if (err.response && err.response.status === 401) history.push("/login");
      });
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([
        item.id,
        item.name,
        item.contactEmail,
        {
          avatar: item.imageUrl
            ? item.imageUrl + "?v=" + moment().format("YYYMMDDss")
            : "",
        },
        c.format("L"),
        u.format("L"),
      ]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    apiService
      .deleteAccount(id)
      .then((result) => {
        setDummyCounter(dummyCounter + 1);
      })
      .catch((err) => {
        console.log("error: ", err);
        if (err.response && err.response.status === 401) history.push("/login");
      });
  };

  const handleAdd = () => {
    history.push("/accounts/add");
  };

  const handleEdit = (event) => {
    history.push("/accounts/" + event.currentTarget.dataset.tag + "/edit");
  };

  const headCells = [
    { id: "Name", numeric: false, disablePadding: true, label: "Company" },
    {
      id: "ContactEmail",
      numeric: false,
      disablePadding: false,
      label: "Contact Email",
    },
    { id: "ImageData", numeric: false, disablePadding: false, label: "Image" },
    {
      id: "CreatedDate",
      numeric: true,
      disablePadding: false,
      label: "Created Date",
    },
    {
      id: "UpdatedDate",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <EntityListFrame>
        <TitleCard
          title="Accounts"
          imgSrc="/images/ingleton-2443106_1920.jpg"
        />
        <Grid item xs={12}>
          {rows === "" ? (
            ""
          ) : (
            <Switch>
              {userContext.isSuperAdmin ? (
                <ProtectedRoute exact path={`${match.path}/add`}>
                  <AccountEntry action="add" />
                </ProtectedRoute>
              ) : (
                ""
              )}
              <ProtectedRoute exact path={`${match.path}/:id/edit`}>
                <AccountEntry action="edit" />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}`}>
                <EntityTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                  canAdd={userContext.isSuperAdmin}
                />
              </ProtectedRoute>
              <Redirect to="/dashboard" />
              <ProtectedRoute />
            </Switch>
          )}
        </Grid>
        <DeleteDialog
          action={pendingDeleteAction}
          handleDelete={handleDelete}
          title="Delete Account?"
          body="Are you sure you want to delete this account with all its data? Once deleted it cannot be undone."
        />
      </EntityListFrame>
    </>
  );
}

export default withRouter(Accounts);
