import React, { useState, useEffect, useRef, useContext } from "react";
import { Grid, Divider, TextField, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LoadingBar from "react-top-loading-bar";
import { useHistory } from "react-router";
import apiService from "../../../services/api.service";
import { useParams } from "react-router-dom";
import Alert from "../../../wrappers/Alert";
import Button from "../../../wrappers/Button";
import Collapse from "@material-ui/core/Collapse";
import { ReloadContext } from "../../../contexts/ReloadContext";
import { UserContext } from "../../../contexts/UserContext";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: "-8px",
    marginBottom: theme.spacing(2),
  },
}));

const CategoryEntry = (props) => {
  const [name, setName] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [,setCateogry] = useState();
  const [accountId, setAccountId] = useState();
  const [accounts, setAccounts] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [userContext,] = useContext(UserContext);

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [sortOrderErrorMessage, setSortOrderErrorMessage] = useState("");
  const [,setReloadContext] = useContext(ReloadContext);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      progressRef.current.continuousStart();
      apiService
        .getCategory(id)
        .then((result) => {
          setCateogry(result.data);
          setName(result.data.name);
          setSortOrder(result.data.sortOrder);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();

          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    }
  }, []);

  useEffect(() => {
    progressRef.current.continuousStart();
    if (isEdit()) {
      apiService.getTenants(0, 0, "desc", "Name").then((tenantResult) => {
        setAccounts(tenantResult.data.list);
        apiService
          .getCategory(id)
          .then((categoryResult) => {
            setCateogry(categoryResult.data);
            setName(categoryResult.data.name);
            setSortOrder(categoryResult.data.sortOrder);
            setAccountId(categoryResult.data.tenantID);
            progressRef.current.complete();
          })
          .catch((err) => {
            console.log("error: ", err);
            if (progressRef && progressRef.current)
              progressRef.current.complete();
            if (err.response && err.response.status === 401)
              history.push("/login");
          });
      });
    } else {
      apiService
        .getTenants(0, 0, "desc", "Name")
        .then((result) => {
          setAccounts(result.data.list);
          setAccountId(result.data.list[0].id);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error: ", err);
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          if (err.response && err.response.status === 401)
            history.push("/login");
        });
    }
  }, []);

  const classes = useStyles();
  const progressRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    if (!isEdit()) {
      apiService
        .createCategory({
          name: name,
          sortOrder: parseInt(sortOrder),
          tenantID: accountId,
        })
        .then((result) => {
          progressRef.current.complete();
          if (result.errors.length > 0) {
            for (let error of result.errors) {
              console.log("error", error);
              handleSubmitError(error);
            }
          } else {
            setDisableSaveButton(true);
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
        })
        .catch((err) => {
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
    } else {
      apiService
        .updateCategory({
          id: id,
          name: name,
          sortOrder: parseInt(sortOrder),
          tenantID: accountId,
        })
        .then((result) => {
          progressRef.current.complete();
          if (result.errors.length > 0) {
            for (let error of result.errors) {
              console.log("error", error);
              handleSubmitError(error);
            }
          } else {
            setSaveSuccess(true);
            setReloadContext(new Date());
          }
        })
        .catch((err) => {
          if (progressRef && progressRef.current)
            progressRef.current.complete();
          console.log("error", err);
        });
    }
  };

  const handleSubmitError = (error) => {
    if (error.propertyName === "TenantID") {
      setAccountErrorMessage(error.errorMessage);
    }
    if (error.propertyName === "Name") {
      setNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === "SortOrder") {
      setSortOrderErrorMessage(error.errorMessage);
    }
  };
  const clearErrors = () => {
    setSaveSuccess(false);
    setAccountErrorMessage("");
    setNameErrorMessage("");
    setSortOrderErrorMessage("");
  };

  const isEdit = () => {
    return id ? true : false;
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height="3px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={userContext && userContext.themeColor === 1 ? "light" : "dark"}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          Your category was {isEdit() ? "updated" : "created"}
        </Alert>
      </Collapse>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            style={{ display: "flex" }}
            container
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          ></Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Account"
                      margin="dense"
                      name="account"
                      select
                      disabled={isEdit()}
                      onChange={(e) => setAccountId(e.target.value)}
                      required
                      SelectProps={{ native: true }}
                      error={!(accountErrorMessage === "")}
                      helperText={
                        !(accountErrorMessage === "") ? accountErrorMessage : ""
                      }
                      value={accountId || ""}
                    >
                      {accounts &&
                        accounts.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name || ""}
                            </option>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      margin="dense"
                      name="name"
                      error={!(nameErrorMessage === "")}
                      helperText={
                        !(nameErrorMessage === "") ? nameErrorMessage : ""
                      }
                      onChange={(e) => setName(e.target.value)}
                      required
                      value={name || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Sort Order"
                      margin="dense"
                      type="number"
                      name="sortorder"
                      error={!(sortOrderErrorMessage === "")}
                      helperText={
                        !(sortOrderErrorMessage === "")
                          ? sortOrderErrorMessage
                          : ""
                      }
                      onChange={(e) => setSortOrder(e.target.value)}
                      required
                      value={sortOrder || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          className={classes.topMargin}
        >
          <Button
            theme={
              userContext && userContext.themeColor === 1 ? "light" : "dark"
            }
            type="submit"
            disabled={disableSaveButton}
            className={classes.buttons}
          >
            Save
          </Button>
          <Button
            theme={
              userContext && userContext.themeColor === 1 ? "light" : "dark"
            }
            className={clsx(classes.buttons, classes.buttonRight)}
            onClick={() => props.history.goBack()}
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default CategoryEntry;
