import axios from "axios";
import authService from "./auth.service";

class apiHelperService {
  getAuthClient = () => {
    let storedUser = JSON.parse(sessionStorage.getItem("user"));
    const defaultOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let instance = axios.create(defaultOptions);

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = storedUser
        ? `Bearer ${storedUser.accessToken}`
        : "";
      return config;
    });

    return instance;
  };

  internalAPICall = (call, params) =>
  {
    return call.then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        let storedUser = JSON.parse(sessionStorage.getItem("user"));
        if (storedUser && storedUser.refreshToken) {
          return new Promise((resolve, reject) => {
            authService
              .loginWithRefreshToken(storedUser.refreshToken)
              .then(() => {
                let call = this.getAuthClient().get(...params);
                resolve(
                  call
                    .then((response) => response.data)
                    .catch((err) => {
                      throw err;
                    })
                );
              });
          });
        }
      } else {
        console.log(
          "error message was not 401 it was " + err.response.status + " ."
        );
        throw err;
      }
    });
  }

  getCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().get(...params), params);
  }

  postCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().post(...params), params);
  }

  patchCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().patch(...params), params);
  }

  deleteCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().delete(...params), params);
  }
}

export default new apiHelperService();
